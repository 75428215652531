<template>
  
  <div class="view-policy"> 
    
    <base-content-wrapper-new>
      <base-icon app="constlet" size="xl">constlet</base-icon>
      <base-title size="m" mB="s">Privacy Policy for all Constlet Apps</base-title>
      <br><br>
      TLDR: <b>We do not collect any data.</b>
      <p>One of our main priorities is the privacy of our users. 
        This Privacy Policy document contains types of information 
        that are not collected and recorded by any of our apps and how we use it.
      </p>      
      
      <b>1. Information We Do Not Collect</b>
      <p>
        Our apps do not collect any personal information from its users. We do not 
        require users to register or provide any personal details (such as name, 
        email address, contact number, etc.) to use our application. Our applications 
        do not require login credentials, and we do not track or store any personal 
        data related to your identity.
      </p>
      <b>2. How We Protect Your Information</b>
      <p>
        As our apps do not collect any personal information, there are no 
        risks of your personal data being exposed or misused. Our applications 
        are designed to function without the need for personal data, ensuring 
        your use of the application remains private and secure.
      </p>
      <b>3. Third-Party Services</b>
      <p>
        Our applications may use third-party services that are not operated by us. 
        Please note that we do not have any control over and assume no responsibility 
        for the content, privacy policies, or practices of any third-party sites or services.
        We strongly advise you to review the Privacy Policy of every site you visit that is 
        linked from our applications.
      </p>     
      <b>4. Changes to This Privacy Policy</b>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of 
        any changes by posting the new Privacy Policy on this page. You are advised 
        to review this Privacy Policy periodically for any changes. Changes to this 
        Privacy Policy are effective when they are posted on this page.
      </p>
      
      <b>5. Your Consent</b>
      <p>
        By using our applications, you hereby consent to our Privacy Policy and agree to its terms.
      </p>   
    </base-content-wrapper-new>
   
  </div>
</template>

<script>

export default {
  name: "privacyPolicy",

  head: {
    title: {
      inner: 'Constlet Apps Privacy Policy'
    }
  }
};
</script>

<style lang="scss" scoped>
.view-policy {
  padding: 1em;
}
</style>
